import jwt_decode from 'jwt-decode';
import moment from 'moment';
import apiClient from 'core/api';
import { uniq } from 'ramda';
import {
  ACCESS_TOKEN_KEY,
  VipocGroup,
  REFRESH_TOKEN_KEY,
  CURRENT_OPCO_KEY,
  CURRENT_LANG_KEY,
  AD_GROUP_MAPPINGS
} from './constants';
import { envs } from 'application/envHandler';

export interface DecodedUser {
  name: string;
  email: string;
  opco: string;
  opcos: Array<string>;
  lang: string;
  langs: Array<string>;
  langsCalendar: Record<string, string>;
  username: string;
  exp: string;
  groups: Array<VipocGroup>;
  'cognito:groups': Array<string>;
  token: string;
}

function extractRole(cognitoGroup: string): VipocGroup | null {
  const parts = cognitoGroup.split('.');
  if (parts.length !== 4) {
    return null;
  }

  const [prefix, moduleName, role, opco, lang] = parts;
  return {
    prefix,
    moduleName,
    role,
    opco,
    lang
  };
}

function getAllOpcoRole(group: any, allGroups: any) {
  let aoRoles: any[] = [];
  let permission = group.split('-')[4];
  let accessLevel = group.split('-')[5];
  for (let [key, value] of Object.entries(allGroups)) {
    if (key.includes(permission) && key.includes(accessLevel)) {
      aoRoles.push(value);
    }
  }
  return aoRoles;
}

function getCurrentOpco() {
  return localStorage.getItem(CURRENT_OPCO_KEY);
}

function getCurrentLanguage() {
  return localStorage.getItem(CURRENT_LANG_KEY);
}

function getCurrentUser(): DecodedUser {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  const emptyUser = {
    groups: [],
    name: '',
    email: '',
    opco: '',
    opcos: [],
    lang: '',
    langs: [],
    langsCalendar: {},
    username: '',
    exp: '',
    'cognito:groups': [],
    token: ''
  };

  if (token) {
    const decodedUser = jwt_decode(token) as any;

    type keymap = { [key: string]: any };
    let adGroupMapper: keymap | any = null;

    if (localStorage.getItem(AD_GROUP_MAPPINGS)) {
      adGroupMapper = JSON.parse(localStorage.getItem(AD_GROUP_MAPPINGS) || '[]');
    }

    let userAdGroups = decodedUser['custom:adgroups'] || '';
    userAdGroups = userAdGroups
      .replace('[', '')
      .replace(']', '')
      .split(',')
      .map((grp: string) => grp.trim());

    let allOpco: any[] = [];
    if (adGroupMapper) {
      userAdGroups = userAdGroups.map((group: any) => {
        if (group.endsWith('AO')) {
          allOpco = allOpco.concat(getAllOpcoRole(group, adGroupMapper));
          return group;
        }
        return adGroupMapper[group] || group;
      });
    }
    let userGroups = decodedUser['cognito:groups'] || [];
    userGroups = allOpco.concat(userGroups);
    userGroups = userAdGroups.concat(userGroups);
    userGroups = Array.from(new Set(userGroups)) || [];

    const groups: Array<VipocGroup> = userGroups
      .map((group: string) => extractRole(group))
      .filter((extractedRole: VipocGroup) => extractedRole !== null);

    if (groups.length === 0) {
      return emptyUser;
    }

    const availableOpcos: Array<string> = uniq(groups.map((role: VipocGroup) => role.opco));

    if (availableOpcos.length === 0) {
      redirectLogout()
      return emptyUser;
    }

    let opco = getCurrentOpco();

    let availableLanguages: Array<string> = [];
    if (envs.REACT_APP_LNG) {
      availableLanguages = envs.REACT_APP_LNG.split(',');
    }

    let availableLanguagesCalendar: Record<string, string> = {};
    if (envs.REACT_APP_CALENDAR_LNG) {
        envs.REACT_APP_CALENDAR_LNG.split(',').map((e: any) =>{
        let value = e.split(":");
        availableLanguagesCalendar = {...availableLanguagesCalendar,[value[0]]: (value[1] || value[0])}
      });
    }

    let lang = getCurrentLanguage();

    const decoded = {
      ...decodedUser,
      opco,
      opcos: availableOpcos,
      langs: availableLanguages,
      langsCalendar: availableLanguagesCalendar,
      lang,
      groups,
      username: decodedUser['cognito:username'],
      token
    };

    return decoded;
  }

  return emptyUser;
}

function isSuperUser(module: string) {
  return getCurrentUser().groups.some(
    (it) =>
      it.prefix === 'module' &&
      it.moduleName === module &&
      it.role === 'su' &&
      it.opco === getCurrentOpco()
  );
}

async function refreshToken() {
  const raw = localStorage.getItem('EXPIRE_DATE');
  if (raw) {
    const expirationDate = moment(raw);
    if (expirationDate.isBefore(moment())) {
      const refreshTokens = localStorage.getItem(REFRESH_TOKEN_KEY);
      return apiClient
        .post(`${envs.REACT_APP_API_URL}/auth/token-refresh`, {
          refreshTokens,
          username: getCurrentUser().username
        })
        .then((response) => {
          localStorage.setItem(ACCESS_TOKEN_KEY, response.data.idToken);
        });
    }
  }
}

async function refreshTokenByTime() {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  return apiClient
    .post(`${envs.REACT_APP_API_URL}/auth/token-refresh`, {
      refreshToken,
      username: getCurrentUser().username
    })
    .then((response) => {
      localStorage.setItem(ACCESS_TOKEN_KEY, response.data.idToken);
    });
}

function switchOpco(opco: string) {
  localStorage.setItem(CURRENT_OPCO_KEY, opco);
  window.location.href = '/';
}

function switchLanguage(lang: string) {
  localStorage.setItem(CURRENT_LANG_KEY, lang);
  window.location.reload();
}

function logout() {
  localStorage.clear();
}

function redirectLogout() {
  // window.location.href = envs.REACT_APP_IS_LOCAL ? '/' : `${envs.REACT_APP_COGNITO_LOGIN_URL}`
  window.location.href = `${envs.REACT_APP_COGNITO_LOGIN_URL}`
}

function logoutAndRedirect() {
  logout()
  redirectLogout()
}

export default {
  getCurrentUser,
  isSuperUser,
  refreshToken,
  refreshTokenByTime,
  logout,
  switchOpco,
  getCurrentOpco,
  switchLanguage,
  getCurrentLanguage,
  redirectLogout,
  logoutAndRedirect,
};
